import { APP_ID, NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { RouterModule } from '@angular/router';
import { UserIdleModule } from 'angular-user-idle';
import { DevExtremeModule } from 'devextreme-angular';
import * as signalR from '@microsoft/signalr';
import { AppComponent } from './app.component';
import { SearchComponent } from './search/search.component';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/forgotPassword/forgotPassword.component';
import { AccountHomeComponent } from './account/home/accounthome.component';
import { MyAccountComponent } from './account/myAccount/myAccount.component';
import { TranslationComponent } from './account/translation/translation.component';
import { OsgConnectionComponent } from './account/osgConnection/osgConnection.component';
import { AdminComponent } from './account/admin/admin.component';
import { AdminLoginsComponent } from './account/admin/admin-logins/admin-logins.component';
import { ChangePasswordComponent } from './account/changePassword/changePassword.component';
import { CatListComponent } from './catalog/catalogList/catList.component';
import { CatalogComponent } from './catalog/browse/browse.component';
import { MenuComponent } from './shared/menu/menu.component';
import { HelpComponent } from './shared/help/help.component';
import { JobListComponent } from './Jobs/jobList.component';
import { HomeComponent } from './home/home.component';
import { NewCatalogComponent } from './catalog/functions/newCatalog/newCatalog.component';
import { ImportMimeComponent } from './catalog/functions/import/importMime.component';
import { ImportFileComponent } from './catalog/functions/import/importFile.component';
import { TranslateCatalogComponent } from './catalog/functions/translateCatalog/translateCatalog.component';
import { ImportMediaComponent } from './catalog/functions/import/importMedia.component';
import { ImportTranslationsComponent } from './catalog/functions/import/importTranslations.component';
import { ProductViewComponent } from './productView/productView.component';
import { ImportCatalogComponent } from './catalog/functions/import/importCatalog.component';
import { ImportCategoryComponent } from './catalog/functions/import/category/importCategory.component';
import { MediaPoolComponent } from './catalog/mediaPool/mediaPool.component';
import { CatalogBuyerComponent } from './catalog/catalog/catalogBuyer.component';
import { CatalogDetailComponent } from './catalog/catalog/catalogDetail.component';
import { CatalogClassificationComponent } from './catalog/catalog/catalogClassification.component';
import { CatalogOsgComponent } from './catalog/catalog/catalogOsg.component';
import { CatalogPriceListComponent } from './catalog/catalog/catalogPriceList.component';
import { CatalogPrintComponent } from './catalog/catalog/catalogPrint.component';
import { CatalogSettingsComponent } from './catalog/catalog/catalogSettings.component';
import { CatalogShopwareComponent } from './catalog/catalog/catalogShopware.component';
import { CatalogSupplierComponent } from './catalog/catalog/catalogSupplier.component';
import { CategoryDetailComponent } from './catalog/category/categoryDetail.component';
import { ImpressumComponent } from './shared/impressum/impressum.component';
import { ProductDetailComponent } from './catalog/product/productDetail.component';
import { ProductDetailValidationComponent } from './catalog/product/productDetailValidation.component';
import { ExportCatalogComponent } from './catalog/functions/export/catalog/exportCatalog.component';
import { ExportParameterComponent } from './catalog/functions/export/catalog/exportParameter/exportParameter.component';
import { ExportCategoryComponent } from './catalog/functions/export/category/exportCategory.component';
import { BackUpComponent } from './catalog/functions/backup/backup.component';
import { JobDataComponent } from './catalog/functions/jobdata/jobdata.component';
import { ModelService } from './Services/model.service';
import { MediaService } from './Services/media.service';
import { LoginService } from './Services/login.service';
import { SystemService } from './Services/system.service';
import { TranslationService } from './Services/translation.service';
import { OsgConnectionService } from './Services/osgConnection.service';
import { HazmatClassService } from './Services/hazmatClass.service';
import { WarrantyClassService } from './Services/warrantyClass.service';
import { JobService } from './Services/job.service';
import { BackupService } from './Services/CatalogManagement/backup.service';
import { CatalogService } from './Services/CatalogManagement/catalog.service';
import { CategoryService } from './Services/CatalogManagement/category.service';
import { ShopService } from './Services/shop.service';
import { UpdateTemplateService } from './Services/updateTemplate.service';
import { CombineCatalogService } from './Services/CatalogManagement/combineCatalog.service';
import { DataQualityComponent } from './catalog/dataQuality/dataQuality.component';
import { DataQualityStatisticComponent } from './catalog/dataQuality/dataQualityStatistic.component';
import { DataQualityGroupsComponent } from './catalog/dataQuality/dataQualityGroups.component';
import { TemplateExcelComponent } from './catalog/functions/template/templateExcel.component';
import { TemplateProductComponent } from './catalog/functions/template/templateProduct.component';
import { ValidFeatureSystemComponent } from './Feature/validFeatureSystem.component';
import { ValidFeatureComponent } from './Feature/validFeature.component';
import { ValidFeatureValueComponent } from './Feature/validFeatureValue.component';
import { ValidFeatureService } from './Services/validFeature.service';
import { ClassificationService } from './Services/classification.service';
import { ClassificationSystemComponent } from './classification/classificationSystem.component';
import { ClassificationTreeComponent } from './classification/classificationTree.component';
import { EditClassificationSystemComponent } from './classification/editClassificationSystem.component';
import { ClassificationGroupComponent } from './classification/classificationGroup.component';
import { Infos } from './catalog/shared/infos';

import { Mimes } from './catalog/shared/mimes';
import { Misc } from './catalog/shared/misc';
import { Prices } from './catalog/shared/prices';
import { References } from './catalog/shared/productReferences/product-references.component';
import { SEO } from './catalog/shared/seo';
import { UDX } from './catalog/shared/udx';
import { ShopView } from './catalog/product/shopView.component';
import { ShopViewLukas } from './catalog/product/shopViewLukas.component';
import { UserActionComponent } from './catalog/functions/userAction/userAction.component';
import { UserActionDeleteFeatureSystemsComponent } from './catalog/functions/userAction/userAction-deleteFeatureSystems/userAction-deleteFeatureSystems.component';
import { UserActionRenameFeatureSystemComponent } from './catalog/functions/userAction/userAction-renameFeatureSystem/userAction-renameFeatureSystem.component';
import { UserActionRenameFeaturesComponent } from './catalog/functions/userAction/userAction-renameFeatures/userAction-renameFeatures.component';
import { UserActionCopyMediaFromOtherCatalogComponent } from './catalog/functions/userAction/userAction-copyMediaFromOtherCatalog/userAction-copyMediaFromOtherCatalog.component';
import { UserActionCompareCatalogComponent } from './catalog/functions/userAction/userAction-compareCatalog/userAction-compareCatalog.component';
import { UserActionDeleteFeaturesComponent } from './catalog/functions/userAction/userAction-deleteFeatures/userAction-deleteFeatures.component';
import { UserActionUpdateCatalogComponent } from './catalog/functions/userAction/userAction-updateCatalog/userAction-updateCatalog.component';
import { UserActionUpdateToCatalogComponent } from './catalog/functions/userAction/userAction-updateToCatalog/userAction-updateToCatalog.component';
import { AdminService } from './Services/admin.service';
import { ProductClassificationComponent } from './classification/productClassification.component';
import { HazmatclassesComponent } from './hazmatclasses/hazmatclasses.component';
import { HazmatclassComponent } from './hazmatclasses/hazmatclass.component';
import { WarrantyclassesComponent } from './warrantyclasses/warrantyclasses.component';
import { WarrantyclassComponent } from './warrantyclasses/warrantyclass.component';
import { FeedbackService } from './Services/feedback.service';
import { DataQualityPrintChartComponent } from './catalog/dataQuality/dataQualityPrintChart.component';
import { UserActionCreateTechnicalDetailsComponent } from './catalog/functions/userAction/userAction-createTechnicalDetails/userAction-createTechnicalDetails.component';
import { UserActionSearchByListComponent } from './catalog/functions/userAction/userAction-searchByList/userAction-searchByList.component';
import { UserActionDeleteByListComponent } from './catalog/functions/userAction/userAction-deleteByList/userAction-deleteByList.component';
import { UserActionConvertPicturesComponent } from './catalog/functions/userAction/userAction-convertPictures/userAction-convertPictures.component';
import { UpdateTemplatesComponent } from './updateTemplates/updateTemplates.component';
import { UpdateTemplateComponent } from './updateTemplates/updateTemplate/updateTemplate.component';
import { CatalogMavisComponent } from './catalog/catalog/catalogMavis.component';
import { MavisConnectionComponent } from './account/mavisConnection/mavisConnection.component';
import { MavisConnectionService } from './Services/mavisConnection.service';
import { OxomiConnectionService } from './Services/oxomiConnection.service';
import { OxomiSortimentService } from './Services/oxomiSortiment.service';
import { UserActionSpreadCatalogComponent } from './catalog/functions/userAction/userAction-spreadCatalog/userAction-spreadCatalog.component';
import { EasyCatalogComponent } from './account/easyCatalog/easyCatalog.component';
import { PrintService } from './Services/print.service';
import { ViewService } from './Services/view.service';
import { TranslationHelperComponent } from './ui/translationHelper/translationHelper.component';
import { CatalogShopware6Component } from './catalog/catalog/catalogShopware6.component';
import { CategoriesComponent } from './catalog/shared/categories.component';
import { UserActionHarmonizeFeatureSystems } from './catalog/functions/userAction/userAction-harmonizeFeaturesytems/userAction-harmonizeFeatureSystems.component';
import { UserActionExportReportProductDatasheet } from './catalog/functions/userAction/userAction-exportReportProductDatasheet/userAction-exportReportProductDatasheet.component';
import { UserActionRemoveUdxOnProducts } from './catalog/functions/userAction/userAction-removeUdxOnProducts/userAction-removeUdxOnProducts.component';
import { UserActionDeleteMediaByPurpose } from './catalog/functions/userAction/userAction-deleteMediaByPurpose/userAction-deleteMediaByPurpose.component';
import { SendToNWConfigService } from './Services/sendToNWConfig.service';
import { SendToNWConfigComponent } from './account/sendToNWConfig/sendToNWConfig.component';
import { CatalogTemplatesComponent } from './catalog/catalog/catalogTemplates/catalogTemplates.component';
import { CategoryTemplatesComponent } from './catalog/category/categoryTemplates/categoryTemplates.component';
import { ProductStateComponent } from './productState/productState.component';
import { ProductStateService } from './Services/productState.service';
import { ProductLegalComponent } from './catalog/shared/productLegal.component';
import { NpBooleanRadioGroupComponent } from './ui/booleanRadioGroup/booleanRadioGroup.component';
import { EditFeatureSystemList } from './catalog/shared/features/featuresystems/featuresystems.component';
import { EditFeatureValueList } from './catalog/shared/features/features/features.component';
import { FeatureValueComponent } from './catalog/shared/features/featurevalue/featurevalue.component';
import { ProductReportingComponent } from './Reporting/productreporting.component';
import { ProductReportingService } from './Services/productreporting.service';
import { FooterComponent } from './shared/footer/footer.component';
import { LogoComponent } from './shared/logo/logo.component';
import { FullscreenPageComponent } from './shared/layout/fullscreen-page.component';
import { ContentColumnComponent } from './shared/layout/content-column.component';
import { NavigationColumnComponent } from './shared/layout/navigation-column.component';
import { HelpColumnComponent } from './shared/layout/help-column.component';
import { FeatureMappingService } from './Services/featureMapping.service';
import { FeatureMappingComponent } from './featureMapping/featureMapping.component';
import { FeatureMappingDetailComponent } from './featureMapping/featureMappingDetail.component';
import { NpDialogComponent, NpDialogButton } from './shared/dialog/NpDialog.component';
import { OrderDetailComponent } from './catalog/shared/orderDetail.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { TemplateListComponent } from './templateEditor/templateList.component';
import { TemplateSelectorComponent } from './templateEditor/templateSelector.component';
import { NpInputControlComponent } from './ui/inputControl/inputControl.component';
import { NewTemplateEditorComponent } from './templateEditor/template-editor.component';
import { TemplateEditorDialogComponent } from './templateEditor/template-editor-dialog.component';
import { UserActionHarmonizeFeaturesComponent } from './catalog/functions/userAction/userAction-harmonizeFeatures/userAction-harmonizeFeatures.component';
import { NewTemplateComponent } from './templateEditor/newTemplate.component';
import { WawiSettingsComponent } from './account/wawiSettings/wawiSettings.component';
import { WawiSettingsService } from './Services/wawiSettingsService.service';
import { WawiComponent } from './catalog/shared/wawi/wawi.component';
import { ProductService } from './Services/CatalogManagement/product.service';
import { PinDataComponent } from './Jobs/pindata.component';
import { UserActionDeleteDuplicateMimesComponent } from './catalog/functions/userAction/userAction-deleteDuplicateMimes/userAction-deleteDuplicateMimes.component';
import { SupplierPidMappingComponent } from './supplierPidMapping/supplierPidMapping.component';
import { SupplierPidMappingService } from './Services/supplierPidMapping.service';
import { ImportSupplierPidMappingsComponent } from './catalog/functions/import/supplierPidMapping/supplierPidMappings.component';
import { UserActionSetWawiSupplierPidsComponent } from './catalog/functions/userAction/userAction-setWawiSupplierPids/userAction-setWawiSupplierPids.component';
import { DeeplConnectionComponent } from './account/deeplConnection/deeplConnection.component';
import { DeeplConnectionService } from './Services/deeplConnection.service';
import { ProductReportingDialogComponent } from './Reporting/productreportingdialog.component';
import { NpHelpButtonComponent } from './shared/helpButton/NpHelpButton.component';
import { NpHelpOption } from './shared/helpButton/NpHelpButton.component';
import { NewsComponent } from './news/news.component';
import { NewsService } from './Services/news.service';
import { UserManagementService } from './Services/userManagment.service';
import { RecurringJobsComponent } from './account/recurringJobs/recurringJobs.component';
import { FtpServerComponent } from './recurringJobs/ftpServer/ftpServer.component';
import { FtpImportComponent } from './recurringJobs/ftpImport/ftpImport.component';
import { CombineCatalogsComponent } from './catalog/functions/combine/combineCatalogs.component';
import { SeoComponent } from './catalog/category/setFields/seo.component';
import { CustomerService } from './Services/customer.service';
import { UserService } from './Services/user.service';
import { GevisComponent } from './catalog/gevis/gevis.component';
import { UserActionCreateVariantsFeatureSystemComponent } from './catalog/functions/userAction/userAction-createVariantsFeatureSystem/userAction-createVariantsFeatureSystem.component';
import { FeatureMappingProfilComponent } from './catalog/functions/import/featureMappingProfil/featureMappingProfil.component';
import { EdeSortimentsAboComponent } from './account/edeSortimentsAbo/edeSortimentsAbo.component';
import { EdeMemberIdService } from './Services/edeMemberId.service';
import { CategoryOsgComponent } from './catalog/category/osg/categoryOsg.component';
import { EdeSortimentsListComponent } from './edeSortimentsList/edeSortimentsList.component';
import { EdeSortimentService } from './Services/edeSortiment.service';
import { EdeSortimentsVersionComponent } from './edeSortimentsList/edeSortimentsVersion/edeSortimentsVersion.component';
import { WawiListService } from './Services/wawiList.service';
import { WawiListComponent } from './wawiLists/wawiLists.component';
import { JobDetailComponent } from './Jobs/jobDetail/jobDetail.component';
import { MassDataChangeComponent } from './catalog/browse/massDataChange/massDataChange.component';
import { MassDataChangeService } from './Services/CatalogManagement/massDataChange.service';
import { TeachingSetComponent } from './teachingset/teachingset.component';
import { TeachingSetsComponent } from './teachingset/teachingsets.component';
import { TeachingSetService } from './Services/teachingSet.service';
import { EdcImportService } from './Services/edcImport.service';
import { InvoiceService } from './Services/invoice.service';
import { InvoiceComponent } from './account/invoice/invoice.component';
import { MassGenerateKITextComponent } from './catalog/browse/massGenerateKIText/massGenerateKIText.component';
import { UserActionCreateEnventaMimeComponent } from './catalog/functions/userAction/userAction-createEnventaMime/userAction-createEnventaMime.component';
import { AuthGuardService } from './Services/authGuard.service';
import { CatalogShopware5Component } from './catalog/catalog/shopware5/catalogShopware5.component';
import { Shopware5Service } from './Services/shopware5.service';
import { Shopware5FeatureSystemList } from './catalog/catalog/shopware5/shopware5FeaturesystemList/shopware5FeaturesystemList.component';
import { ProductAsJsonComponent } from './catalog/shared/productAsJson/productAsJson.component';
import { UserActionSplitFeatureValuesFromUnitComponent } from './catalog/functions/userAction/userAction-splitFeatureValuesFromUnit/userAction-splitFeatureValuesFromUnit.component';
import { ProductSupplierComponent } from './catalog/shared/productSupplier/productSupplier.component';
import { OdooService } from './Services/odoo.service';
import { CatalogOdooComponent } from './catalog/catalog/odoo/catalogOdoo.component';
import { UserActionDeleteEmptyFeaturesComponent } from './catalog/functions/userAction/userAction-deleteEmptyFeatures/userAction-deleteEmptyFeatures.component';
import { CategoryShopware6Component } from './catalog/category/shopware6/categoryShopware6.component';
import { OsgSendersComponent } from './osgSender/osgSenders.component';
import { OsgSenderComponent } from './osgSender/osgSender.component';
import { OsgSenderService } from './Services/osgSender.service';
import { SupplierPackingUnits } from './catalog/shared/productSupplier/supplierPackingUnits/supplierPackingUnits.component';
import { UserActionFixClassificationComponent } from './catalog/functions/userAction/userAction-fixClassification/userAction-fixClassification.component';
import { UserActionSetMimePurposeComponent } from './catalog/functions/userAction/userAction-setMimePurpose/userAction-setMimePurpose.component';
import { UserActionMarkAsChangedComponent } from './catalog/functions/userAction/userAction-markAsChanged/userAction-markAsChanged.component';
import { ExportConfigurationService } from './Services/exportConfiguration.service';
import { MaintenanceModeService } from './Services/MaintenanceModeService';
import { FtpServerService } from './Services/ftp/ftpServer.service';
import { FtpImportJobService } from './Services/ftp/ftpImportJob.service';
import { FtpExportJobService } from './Services/ftp/ftpExportJob.service';
import { FtpExportComponent } from './recurringJobs/ftpExport/ftpExport.component';
import { DscInfosComponent } from './account/dscInfos/dscInfos.component';
import { CustomerAddonsOverviewComponent } from './account/admin/customer-addons-overview/customer-addons-overview.component';
import { CategoryFilterComponent } from './catalog/functions/export/catalog/filter/categoryFilter/categoryFilter.component';
import { ManufacturerFilterComponent } from './catalog/functions/export/catalog/filter/manufacturerFilter/manufacturerFilter.component';
import { UdxFilterComponent } from './catalog/functions/export/catalog/filter/udxFilter/udxFilter.component';
import { DateFilterComponent } from './catalog/functions/export/catalog/filter/dateFilter/dateFilter.component';
import { FeatureContentFilterComponent } from './catalog/functions/export/catalog/content/feature-content-filter/featureContentFilter.component';
import { PricelistContentFilterComponent } from './catalog/functions/export/catalog/content/pricelist-content-filter/pricelistContentFilter.component';
import { UdxContentFilterComponent } from './catalog/functions/export/catalog/content/udx-content-filter/udxContentFilter.component';
import { ProductStateFilterComponent } from './catalog/functions/export/catalog/filter/productStateFilter/productStateFilter.component';
import { ExportFilterService } from './Services/exportFilter.service';
import { ExportConfigurationComponent } from './catalog/functions/export/catalog/configuration/exportConfiguration.component';
import { UserActionChangeMimePurposeComponent } from './catalog/functions/userAction/useraction-changemimepurposes/userAction-changeMimePurposes.component';
import { SupplierContentFilterComponent } from './catalog/functions/export/catalog/filter/supplierContentFilter/supplierContentFilter.component';
import { ProductSearchOptionsComponent } from './catalog/catalog/SearchOptions/productSearchOptions.component';
import { ValidationService } from './Services/Validation/validation.service';
import { ValidationComponent } from './account/validation/validation.component';
import { SearchByListFilterComponent } from './catalog/functions/export/catalog/filter/searchByListFilter/searchByListFilter.component';
import { Seo_Component } from './catalog/shared/seo_sea/seo.component';
import { SnippetComponent } from './catalog/shared/seo_sea/snippet.component';
import { PendingChangesGuard } from './ui/pendingChangesGuard/pendingChangesGuard';
import { IntershopConnectionComponent } from './catalog/catalog/intershopConnection/intershopConnection.component';
import { FieldListComponent } from './catalog/functions/export/catalog/fieldList/fieldList.component';
import { UserManagementComponent } from './account/myAccount/userManagement/userManagement.component';
import { AddonService } from './Services/addon.service';
import { ElectronicSalesComponent } from './catalog/shared/electronicSales/electronicSales.component';
import { ProductStateOptionsComponent } from './catalog/catalog/StateOptions/productStateOptions.component';
import { CatalogStateService } from './Services/catalogState.service';
import { CatalogStateComponent } from './catalogState/catalogState.component';
import { StatesComponent } from './states/states.component';
import { UserActionGenerateAITextsComponent } from './catalog/functions/userAction/userAction-generateAITexts/userAction-generateAITexts.component';
import { CsvSettingsComponent } from './catalog/functions/export/catalog/csvSettings/csvSettings.component';
import { FtpSupplierPidMappingsConponent } from './recurringJobs/ftpSupplierPidMappingsImport/ftpSupplierPidMappingsImport.component';
import { FtpSupplierPidMappingsImportJobsService } from './Services/ftp/ftpSupplierPidMappingsImportJobs.service';
import { UserActionApplyWawiMappings } from './catalog/functions/userAction/userAction-applyWawiMappings/userAction-applyWawiMappings.component';
import { WawiMappingChooserComponent } from './catalog/functions/export/catalog/wawiMappingChooser/wawiMappingChooser.component';
import { ElectronicSalesConnectionComponent } from './catalog/catalog/electronicSalesConnection/electronicSalesConnection.component';
import { InterfaceSettingComponent } from './catalog/functions/export/catalog/interface-setting/interface-setting.component';
import { AdvancedTemplateComponent } from './templateEditor/advanced-template/advanced-template.component';
import { AdvancedTemplateFeatureComponent } from './templateEditor/advanced-template-feature/advanced-template-feature.component';
import { AdvancedTemplateMimeComponent } from './templateEditor/advanced-template-mime/advanced-template-mime.component';
import { AdvancedTemplateDefaultFieldComponent } from './templateEditor/advanced-template-defaultfield/advanced-template-defaultfield.component';
import { AdvancedTemplateFixedComponent } from './templateEditor/advanced-template-fixed/advanced-template-fixed.component';
import { RegistrationComponent } from './account/registration/registration.component';
import { DatasourcesComponent } from './components/datasources/datasources.component';
import { DataSourcesService } from './Services/DataProcess/datasources.service';
import { NpDataGridComponent } from './components/npComponents/np-data-grid/np-data-grid.component';
import { ExportParameterSelectorComponent } from './catalog/functions/export/catalog/export-parameter-selector/export-parameter-selector.component';
import { AdvancedTemplateFieldsettingsComponent } from './templateEditor/advanced-template-fieldsettings/advanced-template-fieldsettings.component';
import { AdvancedTemplateDatagridComponent } from './templateEditor/advanced-template-datagrid/advanced-template-datagrid.component';
import { AdvancedTemplatePriceComponent } from './templateEditor/advanced-template-price/advanced-template-price.component';
import { DatasheetManagementComponent } from './account/datasheetManagement/datasheetManagement.component';
import { AdvancedTemplateListFieldComponent } from './templateEditor/advanced-template-listfield/advanced-template-listfield.component';
import { AdvancedTemplateLegalComponent } from './templateEditor/advanced-template-legal/advanced-template-legal.component';
import { TemplateFieldsService } from './Services/templateFields.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterConfigComponent } from './catalog/functions/export/catalog/filter/filter-config/filter-config.component';
import { AdvancedTemplateHeaderComponent } from './templateEditor/advanced-template-header/advanced-template-header.component';
import { AdvancedTemplateCreateComponent } from './templateEditor/advanced-template-create/advanced-template-create.component';
import { OxomiAccessdataComponent } from './account/oxomi-accessdata/oxomi-accessdata.component';
import { OxomiSortimentComponent } from './oxomi-sortiment/oxomi-sortiment.component';
import { DscContactService } from './Services/dscContactService.service';
import { UseractionAssignMimeBySupplierPidComponent } from './catalog/functions/userAction/useraction-assign-mime-by-supplier-pid/useraction-assign-mime-by-supplier-pid.component';
import { TemplateProductCategoriesComponent } from './catalog/shared/template-product-categories/template-product-categories.component';
import { PricedetailComponent } from './catalog/shared/pricedetail/pricedetail.component';
import { DropdownControlComponent } from './ui/dropdown-control/dropdown-control.component';
import { CatalogDataQualityService } from './Services/Validation/catalogDataQuality.service';
import { NpLicenseUpgradeButtonComponent } from './components/npComponents/license-upgrade-button/license-upgrade-button.component';
import { Logistic } from './catalog/shared/productLogistic/logistic.component';
import { Packagings } from './catalog/shared/productPackaging/packaging.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    DscInfosComponent,
    HomeComponent,
    MenuComponent,
    HelpComponent,
    HazmatclassesComponent,
    HazmatclassComponent,
    JobListComponent,
    LoginComponent,
    ForgotPasswordComponent,
    WarrantyclassComponent,
    WarrantyclassesComponent,
    AccountHomeComponent,
    MyAccountComponent,
    ChangePasswordComponent,
    ProductClassificationComponent,
    CatListComponent,
    CategoryOsgComponent,
    CatalogBuyerComponent,
    CatalogClassificationComponent,
    CatalogOsgComponent,
    CatalogMavisComponent,
    CatalogPriceListComponent,
    CatalogPrintComponent,
    CatalogSettingsComponent,
    CatalogShopwareComponent,
    CatalogShopware6Component,
    CatalogSupplierComponent,
    DatasheetManagementComponent,
    NewCatalogComponent,
    ImportMimeComponent,
    ImportFileComponent,
    ImportMediaComponent,
    ImportTranslationsComponent,
    ClassificationGroupComponent,
    TranslationComponent,
    ValidationComponent,
    AdminComponent,
    ClassificationTreeComponent,
    AdminLoginsComponent,
    ImportCatalogComponent,
    ImportCategoryComponent,
    ClassificationSystemComponent,
    ExportCatalogComponent,
    ExportParameterComponent,
    ExportCategoryComponent,
    EasyCatalogComponent,
    DataQualityComponent,
    DataQualityStatisticComponent,
    DataQualityGroupsComponent,
    DataQualityPrintChartComponent,
    DeeplConnectionComponent,
    BackUpComponent,
    JobDataComponent,
    CatalogComponent,
    CatalogDetailComponent,
    CategoryDetailComponent,
    ProductDetailComponent,
    ProductDetailValidationComponent,
    Packagings,
    TranslateCatalogComponent,
    ImpressumComponent,
    MediaPoolComponent,
    MavisConnectionComponent,
    ShopView,
    ShopViewLukas,
    SearchComponent,
    ProductViewComponent,
    EditClassificationSystemComponent,
    EditFeatureSystemList,
    EditFeatureValueList,
    FeatureValueComponent,
    Infos,
    Logistic,
    Mimes,
    Misc,
    Prices,
    References,
    SEO,
    SeoComponent,
    Seo_Component,
    SnippetComponent,
    UDX,
    TemplateExcelComponent,
    TemplateProductComponent,
    TranslationHelperComponent,
    UserActionComponent,
    UserActionHarmonizeFeaturesComponent,
    UserActionCopyMediaFromOtherCatalogComponent,
    UserActionDeleteFeatureSystemsComponent,
    UserActionDeleteFeaturesComponent,
    UserActionDeleteEmptyFeaturesComponent,
    UserActionRenameFeatureSystemComponent,
    UserActionRenameFeaturesComponent,
    UserActionSplitFeatureValuesFromUnitComponent,
    UserActionCompareCatalogComponent,
    UserActionUpdateCatalogComponent,
    UserActionUpdateToCatalogComponent,
    UserActionCreateTechnicalDetailsComponent,
    UserActionSearchByListComponent,
    UserActionDeleteByListComponent,
    UserActionConvertPicturesComponent,
    UserActionSpreadCatalogComponent,
    UserActionExportReportProductDatasheet,
    UserActionRemoveUdxOnProducts,
    UserActionDeleteMediaByPurpose,
    UserActionFixClassificationComponent,
    UserActionCreateEnventaMimeComponent,
    OsgConnectionComponent,
    UpdateTemplatesComponent,
    UpdateTemplateComponent,
    CategoriesComponent,
    UserActionHarmonizeFeatureSystems,
    SendToNWConfigComponent,
    CategoryTemplatesComponent,
    CatalogTemplatesComponent,
    ProductStateComponent,
    ProductLegalComponent,
    NpBooleanRadioGroupComponent,
    ProductReportingComponent,
    FooterComponent,
    LogoComponent,
    FullscreenPageComponent,
    ContentColumnComponent,
    NavigationColumnComponent,
    HelpColumnComponent,
    FeatureMappingComponent,
    FeatureMappingDetailComponent,
    NpDialogComponent,
    NpDialogButton,
    NpHelpOption,
    NpHelpButtonComponent,
    OrderDetailComponent,
    SandboxComponent,
    TemplateListComponent,
    TemplateSelectorComponent,
    NpInputControlComponent,
    NewTemplateEditorComponent,
    NewTemplateComponent,
    TemplateEditorDialogComponent,
    WawiSettingsComponent,
    PinDataComponent,
    ValidFeatureSystemComponent,
    ValidFeatureComponent,
    ValidFeatureValueComponent,
    UserActionDeleteDuplicateMimesComponent,
    WawiComponent,
    SupplierPidMappingComponent,
    ImportSupplierPidMappingsComponent,
    UserActionSetWawiSupplierPidsComponent,
    ProductReportingDialogComponent,
    NewsComponent,
    RecurringJobsComponent,
    FtpServerComponent,
    FtpImportComponent,
    CombineCatalogsComponent,
    GevisComponent,
    UserActionCreateVariantsFeatureSystemComponent,
    FeatureMappingProfilComponent,
    EdeSortimentsAboComponent,
    EdeSortimentsListComponent,
    EdeSortimentsVersionComponent,
    WawiListComponent,
    JobDetailComponent,
    MassDataChangeComponent,
    TeachingSetComponent,
    TeachingSetsComponent,
    InvoiceComponent,
    MassGenerateKITextComponent,
    CatalogShopware5Component,
    Shopware5FeatureSystemList,
    ProductAsJsonComponent,
    ProductSupplierComponent,
    CatalogOdooComponent,
    CategoryShopware6Component,
    OsgSenderComponent,
    OsgSendersComponent,
    SupplierPackingUnits,
    UserActionSetMimePurposeComponent,
    UserActionMarkAsChangedComponent,
    FtpExportComponent,
    CategoryFilterComponent,
    ManufacturerFilterComponent,
    UdxFilterComponent,
    DateFilterComponent,
    FeatureContentFilterComponent,
    PricelistContentFilterComponent,
    UdxContentFilterComponent,
    ProductStateFilterComponent,
    ExportConfigurationComponent,
    CustomerAddonsOverviewComponent,
    UserActionChangeMimePurposeComponent,
    SupplierContentFilterComponent,
    UserActionChangeMimePurposeComponent,
    ProductSearchOptionsComponent,
    SearchByListFilterComponent,
    IntershopConnectionComponent,
    FieldListComponent,
    UserManagementComponent,
    ElectronicSalesComponent,
    ProductStateOptionsComponent,
    CatalogStateComponent,
    StatesComponent,
    UserActionGenerateAITextsComponent,
    CsvSettingsComponent,
    FtpSupplierPidMappingsConponent,
    UserActionApplyWawiMappings,
    WawiMappingChooserComponent,
    ElectronicSalesConnectionComponent,
    InterfaceSettingComponent,
    AdvancedTemplateComponent,
    AdvancedTemplateFeatureComponent,
    AdvancedTemplateMimeComponent,
    AdvancedTemplateDefaultFieldComponent,
    AdvancedTemplateFixedComponent,
    RegistrationComponent,
    DatasourcesComponent,
    NpDataGridComponent,
    ExportParameterSelectorComponent,
    AdvancedTemplateFieldsettingsComponent,
    AdvancedTemplateDatagridComponent,
    AdvancedTemplatePriceComponent,
    AdvancedTemplateListFieldComponent,
    AdvancedTemplateLegalComponent,
    AdvancedTemplateHeaderComponent,
    AdvancedTemplateCreateComponent,
    FilterConfigComponent,
    OxomiAccessdataComponent,
    OxomiSortimentComponent,
    TemplateProductCategoriesComponent,
    UseractionAssignMimeBySupplierPidComponent,
    PricedetailComponent,
    DropdownControlComponent,
    NpLicenseUpgradeButtonComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['api'],
        sendAccessToken: true
      }
    }),
    UserIdleModule.forRoot({
      idle: 28800,
      timeout: 1,
      ping: 900
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonModule,
    FormsModule,
    DevExtremeModule,
    RouterModule.forRoot(
      [
        { path: 'getProduct', component: ProductViewComponent },
        { path: 'printChart', component: DataQualityPrintChartComponent },
        { path: 'forgotPassword', component: ForgotPasswordComponent },
        { path: 'login/:customerId', component: LoginComponent },
        { path: 'login', component: LoginComponent, pathMatch: 'full' },
        { path: 'register', component: RegistrationComponent },
        {
          path: '',
          component: MenuComponent,
          canActivate: [AuthGuardService],
          children: [
            { path: 'Account', component: AccountHomeComponent },
            { path: 'Account/changePassword', component: ChangePasswordComponent },
            { path: 'Account/myAccount', component: MyAccountComponent },
            { path: 'admin', component: AdminComponent },
            { path: 'catalog/:customerId/:catalogId/:catalogTab', component: CatalogComponent },
            { path: 'catalog/:customerId/:catalogId/category/:categoryId', component: CatalogComponent },
            { path: 'catalog/:customerId/:catalogId/product/:productId', component: CatalogComponent },
            { path: 'catalog', component: CatalogComponent },
            { path: 'catalogdetails', component: CatalogComponent },
            { path: 'catalogs', component: CatListComponent },
            { path: 'validfeature', component: ValidFeatureSystemComponent },
            { path: 'classification', component: ClassificationSystemComponent },
            { path: 'editclassification', component: EditClassificationSystemComponent },
            { path: 'hazmatclasses', component: HazmatclassesComponent },
            { path: 'impressum', component: ImpressumComponent },
            { path: 'search', component: SearchComponent },
            { path: 'translation', component: TranslationComponent },
            { path: 'validation', component: ValidationComponent },
            { path: 'warrantyClasses', component: WarrantyclassesComponent },
            { path: 'updateTemplates', component: UpdateTemplatesComponent },
            { path: 'productStates', component: ProductStateComponent },
            { path: 'catalogStates', component: CatalogStateComponent },
            { path: 'states', component: StatesComponent },
            { path: 'productReportDesigner', component: ProductReportingComponent },
            { path: 'featureMapping', component: FeatureMappingComponent },
            { path: 'featureMappingDetail', component: FeatureMappingDetailComponent },
            { path: 'supplierPidMapping', component: SupplierPidMappingComponent },
            { path: 'newsArticle', component: NewsComponent },
            { path: 'combineCatalogs', component: CombineCatalogsComponent },
            { path: 'combineCatalogs/:targetCatalogId/:sourceCatalogId', component: CombineCatalogsComponent },
            { path: 'edeSortiment', component: EdeSortimentsListComponent },
            { path: 'wawiList', component: WawiListComponent },
            { path: 'teachingSets', component: TeachingSetsComponent },
            { path: 'multishopExport', component: OsgSendersComponent },
            { path: 'invoice', component: InvoiceComponent },
            { path: 'templates', component: TemplateListComponent },
            { path: 'templates/expert/:customerId/:templateId', component: AdvancedTemplateComponent },
            { path: 'templates/expert/:customerId/:templateId/:catalogId', component: AdvancedTemplateComponent },
            { path: 'sandbox', component: SandboxComponent },
            { path: 'datasources', component: DatasourcesComponent },
            { path: 'oxomi-sortiment', component: OxomiSortimentComponent },
            { path: '**', redirectTo: 'catalogs' }
          ]
        },
        { path: '**', redirectTo: '/' }
      ],
      {}
    )
  ],
  providers: [
    { provide: APP_ID, useValue: 'ng-cli-universal' },
    ModelService,
    SystemService,
    HazmatClassService,
    WarrantyClassService,
    LoginService,
    ClassificationService,
    ValidFeatureService,
    JobService,
    CatalogService,
    CatalogStateService,
    CategoryService,
    ValidationService,
    MediaService,
    TranslationService,
    ShopService,
    BackupService,
    CatalogDataQualityService,
    AdminService,
    OsgConnectionService,
    FeedbackService,
    UpdateTemplateService,
    MavisConnectionService,
    OxomiConnectionService,
    OxomiSortimentService,
    PrintService,
    SendToNWConfigService,
    ViewService,
    ProductStateService,
    ValidationService,
    ProductReportingService,
    FeatureMappingService,
    WawiSettingsService,
    ProductService,
    SupplierPidMappingService,
    DeeplConnectionService,
    NewsService,
    UserManagementService,
    FtpServerService,
    FtpImportJobService,
    CustomerService,
    UserService,
    EdeMemberIdService,
    EdeSortimentService,
    WawiListService,
    MassDataChangeService,
    TeachingSetService,
    EdcImportService,
    AuthGuardService,
    PendingChangesGuard,
    InvoiceService,
    Shopware5Service,
    OdooService,
    OsgSenderService,
    ExportConfigurationService,
    MaintenanceModeService,
    FtpExportJobService,
    ExportFilterService,
    CombineCatalogService,
    AddonService,
    FtpSupplierPidMappingsImportJobsService,
    DataSourcesService,
    signalR.HubConnectionBuilder,
    TemplateFieldsService,
    DscContactService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
