<div *ngIf="model" class="d-flex flex-column w-100">
  <div *ngIf="!showShop" class="header d-flex flex-row px-3 pt-1 w-100">
    <div class="p-2">
      <span *ngIf="!productMainImage" class="glyphicon glyphicon-picture"></span>
      <img *ngIf="productMainImage" src="{{ productMainImage }}" />
    </div>
    <div class="p-2 flex-fill overflow-hidden">
      <h5>{{ model.descriptionShort }}</h5>
      <span [innerHTML]="model?.descriptionLong"></span>
    </div>
    <productDetailValidation
      class="p-2 align-self-center"
      [(model)]="model"
      (validateProduct)="validateProductForm()"
    ></productDetailValidation>
    <div
      *ngIf="modelService?.loginService?.hasAddonSHOP_VIEW && !modelService?.catalogService?.catalog?.usePrintView"
      class="p-2"
    >
      <div class="d-flex flex-column">
        <div class="dx-field ml-auto">
          <div class="dx-field-label switch-left">{{ 'EDIT' | translate }}</div>
          <dx-switch
            [(value)]="showShop"
            switchedOffText="EDIT"
            switchedOnText="Shop"
            (onValueChanged)="shopViewUpdate($event)"
          ></dx-switch>
          <div class="dx-field-label switch-right">{{ 'Shop' | translate }}</div>
        </div>

        <div
          *ngIf="
            this.modelService?.catalogService?.catalog?.isPublic && !modelService?.catalogService?.catalog?.usePrintView
          "
          class="dx-field"
        >
          <dx-button
            stylingMode="text"
            type="default"
            text="{{ 'Produkt anzeigen' | translate }}"
            (onClick)="openProductView()"
          ></dx-button>
        </div>
      </div>
    </div>
  </div>

  <dx-toolbar class="px-3">
    <dxi-item
      *ngIf="hasSourceProduct()"
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{ type: 'default', text: 'Zum Quellprodukt' | translate, onClick: goToSourceProduct }"
    ></dxi-item>
    <dxi-item
      *ngIf="isVirtualCatalog()"
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{ type: 'default', text: 'Zum Quellprodukt' | translate, onClick: goToSourceProduct }"
    ></dxi-item>
    <dxi-item
      *ngIf="!isVirtualCatalog()"
      location="before"
      widget="dxButton"
      locateInMenu="auto"
      [options]="{ type: 'default', text: 'Description Wizard' | translate, icon: 'startswith', onClick: openConfirm, disabled: !modelService.loginService.hasAddonKI_TEXTS  }"
    ></dxi-item>
    <dxi-item
      *ngIf="!modelService.loginService.hasAddonKI_TEXTS"
      location="before"
      locateInMenu="auto"
    >
      <np-license-upgrade-button></np-license-upgrade-button>
    </dxi-item>
    <dxi-item location="after" locateInMenu="auto">
      <div *dxTemplate>
        <np-help-button>
          <np-help-option
            text="Wiki"
            icon="images/Wiki.svg"
            link="https://knowledge.nextpim.de/grundlagen?utm_source=app.nextpim.de&utm_medium=affiliate&utm_campaign=wikilink#produktdaten"
          ></np-help-option>
          <np-help-option
            text="YouTube"
            icon="images/YouTube.svg"
            link="https://www.youtube.com/watch?v=KdDdemzDtQ8"
          ></np-help-option>
        </np-help-button>
      </div>
    </dxi-item>
  </dx-toolbar>

  <dx-load-panel
    [(visible)]="thinkingVisible"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="false"
    [closeOnOutsideClick]="false"
    message="{{ 'Ich überlege mir was...' | translate }}"
  ></dx-load-panel>
  <!--**************************************************************************-->
  <!--*                            TABS                                        *-->
  <!--**************************************************************************-->
  <div *ngIf="!showShop" class="p-2">
    <dx-tab-panel
      [dataSource]="pages"
      [(selectedIndex)]="modelService.loginService.selectedProductTabIndex"
      class="detailTabs marginBottom"
      [animationEnabled]="true"
      [showNavButtons]="true"
      [swipeEnabled]="false"
      (onTitleClick)="tabChange($event)"
    >
      <div *dxTemplate="let company of 'title'">
        <span>{{ company.title }}</span>
        <span
          *ngIf="company.type == 'MAIN' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productInfosValid"
          class="glyphicon glyphicon-info-sign color1Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="company.type == 'PRICE' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productPricesValid"
          class="glyphicon glyphicon-info-sign color1Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="
            company.type == 'ORDER' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productOrderDetailsValid
          "
          class="glyphicon glyphicon-info-sign color1Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="company.type == 'MIME' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productMimesValid"
          class="glyphicon glyphicon-info-sign color3Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="company.type == 'FEAT' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productFeaturesValid"
          class="glyphicon glyphicon-info-sign color4Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="
            company.type == 'LOGI' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productLogisticsValid
          "
          class="glyphicon glyphicon-info-sign color5Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="
            company.type == 'REFE' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productReferencesValid
          "
          class="glyphicon glyphicon-info-sign color5Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="company.type == 'LEGAL' && modelService.loginService.currentCustomer.automaticValidation && !validationService.validationResult.productLegalValid"
          class="glyphicon glyphicon-info-sign color5Text"
          style="margin-left: 8px"
        ></span>
        <span
          *ngIf="company.type == 'CAT' && hasCatalogMappings(model)"
          class="glyphicon glyphicon-info-sign nextPimBlue"
          style="margin-left: 8px"
        ></span>
      </div>

      <!--INFOS-->
      <div *dxTemplate="let company of 'productTemplate'">
        <infos
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(hasAddonEDENUMBERS)]="modelService.loginService.hasAddonEDENUMBERS"
          [(hasAddonPRODUCT_DETAILS)]="modelService.loginService.hasAddonPRODUCT_DETAILS"
          [isPrint]="modelService?.catalogService?.catalog?.usePrintView"
          [(selectedTranslateLanguage)]="modelService.loginService.selectedTranslateLanguage"
          [(productTranslation)]="modelService.translationService.productTranslation"
          (translationUpdateFunction)="translationUpdateFunctions($event)"
          (onValueChanged)="productUpdate($event)"
        ></infos>
      </div>

      <!--MIMES-->
      <div *dxTemplate="let company of 'medienTemplate'">
        <mimes
          [(model)]="model.mimes"
          [(product)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(catalogId)]="modelService.catalogService.catalog.id"
          [(customerId)]="modelService.loginService.currentUser.customerId"
          (reloadNedded)="reload()"
          (onValueChanged)="productUpdate($event)"
          [(modelService)]="modelService"
        ></mimes>
      </div>

      <!--FEATURES-->
      <div *dxTemplate="let company of 'featuresTemplate'">
        <featuresystems
          [(featureSystems)]="model.featureSystems"
          [(model)]="model"
          [(hintsAktiv)]="model.hintsActiv"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(hasAddonCLASSIFICATION)]="modelService.loginService.hasAddonCLASSIFICATION"
          [(hasAddonECOMMERCE)]="modelService.loginService.hasAddonECOMMERCE"
          [(modelService)]="modelService"
          displayMode="edit"
          (addEcommerceFeatures)="addEcommerceFeatures($event)"
          (addClassificationFeatures)="addClassificationFeatures($event)"
          (onValueChanged)="productUpdate($event)"
        ></featuresystems>
      </div>

      <!--OrderDetail-->
      <div *dxTemplate="let company of 'orderDetailTemplate'">
        <orderDetail
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(hasAddonLEGALITY)]="modelService.loginService.hasAddonLEGALITY"
          [(hasAddonNordwest)]="modelService.loginService.hasAddonNordwest"
          (currentCustomerDefaultLanguage)="(modelService.loginService.currentCustomerDefaultLanguage)"
          (onValueChanged)="productUpdate($event)"
        ></orderDetail>
      </div>

      <!--Preise-->
      <div *dxTemplate="let company of 'preislistenTemplate'">
        <prices
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(hasAddonLEGALITY)]="modelService.loginService.hasAddonLEGALITY"
          [(hasAddonNordwest)]="modelService.loginService.hasAddonNordwest"
          (currentCustomerDefaultLanguage)="(modelService.loginService.currentCustomerDefaultLanguage)"
          [(priceLists)]="modelService.catalogService.catalog.priceLists"
          (onValueChanged)="productUpdate($event)"
        ></prices>
      </div>

      <!--Logistic-->
      <div *dxTemplate="let company of 'logistikTemplate'">
        <logistic
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(hasAddonLEGALITY)]="modelService.loginService.hasAddonLEGALITY"
          [(modelService)]="modelService"
          (onValueChanged)="productUpdate($event)"
        ></logistic>
      </div>

      <!--Referenzen-->
      <div *dxTemplate="let company of 'referenceTemplate'">
        <np-product-references
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(lastImageUpload)]="modelService.catalogService.catalog.lastImageUpload"
          (onValueChanged)="productUpdate($event)"
          (onReferencePrepare)="onReferencePrepare($event)"
          [(modelService)]="modelService"
        ></np-product-references>
      </div>

      <!--ProductSuppliers-->
      <div *dxTemplate="let company of 'productSuppliersTemplate'">
        <productSuppliers
          [(model)]="model.suppliers"
          [(product)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(catalogId)]="modelService.catalogService.catalog.id"
          [(customerId)]="modelService.loginService.currentUser.customerId"
          (reloadNedded)="reload()"
          (onValueChanged)="productUpdate($event)"
          [(modelService)]="modelService"
        ></productSuppliers>
      </div>

      <!-- Electronic Sales -->
      <div *dxTemplate="let company of 'electronicSalesTemplate'">
        <electronicSales
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          (onValueChanged)="productUpdate($event)"
        ></electronicSales>
      </div>

      <!--UDXs-->
      <div *dxTemplate="let company of 'udxTemplate'">
        <udx
          [(model)]="model.udxs"
          [(product)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          (onValueChanged)="productUpdate($event)"
        ></udx>
      </div>

      <!--SEO - SEA-->
      <div *dxTemplate="let company of 'seoTemplate'">
        <seo
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          (onValueChanged)="productUpdate($event)"
        ></seo>
      </div>

      <!--WAWI-->
      <div *dxTemplate="let company of 'wawiTemplate'">
        <wawi
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          (onValueChanged)="productUpdate($event)"
        ></wawi>
      </div>

      <div *dxTemplate="let company of 'categoriesTemplate'">
        <categories
          [(product)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          (reloadNedded)="reload()"
          (onValueChanged)="productUpdate($event)"
        ></categories>
      </div>

      <div *dxTemplate="let company of 'legalTemplate'">
        <productLegal
          [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [showLinkButtons]="true"
          [(warrantyClasses)]="modelService.warrantyClassService.warrantyClasses"
          [(hazmatClasses)]="modelService.hazmatClassService.hazmatClasses"
          (onValueChanged)="productUpdate($event)"
        ></productLegal>
      </div>

      <div *dxTemplate="let company of 'productAsJsonTemplate'">
        <productAsJson></productAsJson>
      </div>
    </dx-tab-panel>
  </div>
  <div *ngIf="showShop" class="px-2 pb-2 pt-1">
    <div class="d-flex flex-row-reverse p-2">
      <div
        *ngIf="modelService?.loginService?.hasAddonSHOP_VIEW && !modelService?.catalogService?.catalog?.usePrintView"
      >
        <div class="dx-field px-2">
          <div class="dx-field-label switch-left">{{ 'EDIT' | translate }}</div>
          <dx-switch
            [(value)]="showShop"
            switchedOffText="EDIT"
            switchedOnText="Shop"
            (onValueChanged)="shopViewUpdate($event)"
          ></dx-switch>
          <div class="dx-field-label switch-right">{{ 'Shop' | translate }}</div>
        </div>
      </div>
      <div *ngIf="modelService.loginService.hasAddonTemplateLukas">
        <div class="dx-field px-2">
          <div class="dx-field-label switch-left">{{ 'norm' | translate }}</div>
          <dx-switch [(value)]="templateLukas" switchedOffText="norm" switchedOnText="LuE"></dx-switch>
          <div class="dx-field-label switch-right">{{ 'LuE' | translate }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="this.modelService.systemService.templateLukas">
      <shopViewLukas></shopViewLukas>
    </div>
    <div *ngIf="!this.modelService.systemService.templateLukas">
      <shopView [(model)]="shopViewModel"></shopView>
    </div>
  </div>
</div>

<dx-popup
  [width]="500"
  [height]="800"
  [showTitle]="true"
  title="{{ 'Description Wizard' | translate }}"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  [(visible)]="confirmVisible"
  contentTemplate="popupContent"
>
  <dxi-toolbar-item
    toolbar="bottom"
    location="after"
    widget="dxButton"
    [options]="{ text: 'Starten' | translate, width: '100%', onClick: confirmGenerate }"
  ></dxi-toolbar-item>
  <div *dxTemplate="let data of 'popupContent'">
    <div class="dx-fieldset">
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Kosten pro Text' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box
              [readOnly]="true"
              format="#,##0.## €"
              [value]="invoiceSetting.pricePerOpenAi"
            ></dx-number-box>
          </div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Benutztes Limit' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.usedLimit"></dx-number-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Limit' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.limit"></dx-number-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Freibetrag' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box [readOnly]="true" format="#,##0.## €" [(value)]="invoiceSetting.allowance"></dx-number-box>
          </div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Training' | translate }}</div>
          <div class="dx-field-value">
            <dx-select-box [items]="teachingSets" [(value)]="teachingSet" displayExpr="name"></dx-select-box>
          </div>
        </div>
      </div>
      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Generiere HTML' | translate }}</div>
          <div class="dx-field-value">
            <dx-switch hint="hintMessage" [(value)]="generateHTML"></dx-switch>
          </div>
        </div>
      </div>

      <div class="p-2 flex-fill">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Textstil' | translate }}</div>
          <div class="dx-field-value">
            <dx-select-box [items]="textstyles" [(value)]="textstyle"></dx-select-box>
          </div>
        </div>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Maximale Länge' | translate }}</div>
      <div class="dx-field-value">
        <dx-number-box min="50" max="10000" [(value)]="maxLength"></dx-number-box>
      </div>
    </div>
  </div>
</dx-popup>
