import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DxTreeListComponent } from 'devextreme-angular';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import isString from 'lodash.isstring';
import isNumber from 'lodash.isnumber';
import { Catalog } from '../../Model/Catalog/Catalog';
import { CatalogPriceList } from '../../Model/Catalog/CatalogPriceList';
import { Category } from '../../Model/Catalog/Category';
import { Feature } from '../../Model/Catalog/FeatureModels/Feature';
import { FeatureSystem } from '../../Model/Catalog/FeatureModels/FeatureSystem';
import { Mime } from '../../Model/Catalog/Mime';
import { Product } from '../../Model/Catalog/Product';
import { ProductState } from '../../Model/Catalog/ProductState';
import { TemplateItemTemplate } from '../../Model/Catalog/TemplateItemTemplate';
import { AddItem } from '../../Model/Dto/AddItem';
import { Clipboard } from '../../Model/Dto/Clipboard';
import { DuplicateItem } from '../../Model/Dto/DuplicateItem';
import { FeatureSystemFilter } from '../../Model/Dto/FeatureSystemFilter';
import { NewCatalog } from '../../Model/Dto/NewCatalog';
import { PriceListFilter } from '../../Model/Dto/PriceListFilter';
import { SearchResult } from '../../Model/Dto/searchResults';
import { ShopConnection } from '../../Model/Dto/ShopConnection';
import { StringResponse } from '../../Model/Dto/StringResponse';
import { TestResult } from '../../Model/Dto/TestResult';
import { TreeItem as TreeElement, TreeItem } from '../../Model/Dto/TreeItem';
import { UrlResponse } from '../../Model/Dto/UrlResponse';
import { JobService } from './../job.service';
import { LoginService } from './../login.service';
import { ShopService } from './../shop.service';
import { ViewService } from './../view.service';
import { ShopwareSalesChannel } from '../../Model/Dto/ShopwareSalesChannel';
import { ShopwareCmsPage } from '../../Model/Dto/ShopwareCmsPage';
import { TranslateService } from '@ngx-translate/core';
import { KeyValueItem } from '../../Model/System/KeyValueItem';
import { ProductService } from './product.service';
import { ShopwareLanguage } from '../../Model/Dto/ShopwareLanguage';
import { SystemService } from './../system.service';
import { ExportParameter } from '../../Model/exportParameter/ExportParameter';
import { ProductSearchOptions } from '../../Model/User/ProductSearchOptions';
import { ValidFeatureUsageReport } from '../../Model/ValidFeatureUsageReport';
import { lastValueFrom } from "rxjs";

@Injectable()
export class CatalogService {
  fileNameToUseInImport: string;

  public importCatalogVisible: boolean = false;
  public action: number = 0;
  public showUserAction: boolean = false;
  showFilter: boolean = false;
  selectedStates: string[] = [];
  selectedStatesMode: string = null;
  selectedStatesTarget: string[] = [];
  selectedStatesTargetMode: string = null;
  useMetadata = false;
  public startMassGenerateKITexts = new EventEmitter();

  constructor(
    private http: HttpClient,
    public loginService: LoginService,
    private jobService: JobService,
    private shopService: ShopService,
    public viewService: ViewService,
    public translate: TranslateService,
    public productService: ProductService,
    public systemService: SystemService
  ) {
    this.catalogs = new Array<Catalog>();
    this.handleRefreshBehavior();
  }

  public tree: TreeElement[];
  public treeList: DxTreeListComponent;
  public treeListTarget: DxTreeListComponent;
  public childs: TreeElement[];
  public selectedNode: any;
  productStates: ProductState[];
  languageCodes: string[];

  public accountMenuVisible = false;
  public actionMenuVisible = false;

  public closeAllPopUps() {
    this.viewService.jobsVisible = false;
    this.accountMenuVisible = false;
    this.actionMenuVisible = false;
    this.exportVisible = false;
    this.translateVisible = false;
  }

  treeRefresh() {
    if (this.treeList != null) {
      this.treeList.instance.refresh();
    }
  }

  treeFilter() {
    let that = this;
    if (this.treeList != null) {
      this.treeList.instance
        .refresh()
        .then(function () {
          if (that.selectedStates.length > 0) {
            let keys = that.getNodeKeys(that.treeList.instance.getNodeByKey(that.selectedCatalogId));
            that.treeList.instance.beginUpdate();
            keys.forEach(function (key) {
              that.treeList.instance.expandRow(key);
            });

            that.treeList.instance.endUpdate();
          }
        })
        .catch(function (error) {
          // ...
        });
    }
  }

  treeFilterTarget() {
    let that = this;
    if (this.treeListTarget != null) {
      this.treeListTarget.instance
        .refresh()
        .then(function () {
          if (that.selectedStatesTarget.length > 0) {
            let keys = that.getNodeKeys(that.treeListTarget.instance.getNodeByKey(that.selectedTargetCatalogId));
            that.treeListTarget.instance.beginUpdate();
            keys.forEach(function (key) {
              that.treeListTarget.instance.expandRow(key);
            });

            that.treeListTarget.instance.endUpdate();
          }
        })
        .catch(function (error) {
          // ...
        });
    }
  }

  collapsTree() {
    let that = this;
    let keys = that.getNodeKeys(that.treeList.instance.getNodeByKey(that.selectedCatalogId));
    that.treeList.instance.beginUpdate();
    keys.forEach(function (key) {
      that.treeList.instance.collapseRow(key);
    });

    that.treeList.instance.endUpdate();
  }
  collapsTreeTarget() {
    let that = this;
    let keys = that.getNodeKeys(that.treeListTarget.instance.getNodeByKey(that.selectedTargetCatalogId));
    that.treeListTarget.instance.beginUpdate();
    keys.forEach(function (key) {
      that.treeListTarget.instance.collapseRow(key);
    });

    that.treeListTarget.instance.endUpdate();
  }

  getNodeKeys(node) {
    let keys = [];
    keys.push(node.key);
    var self = this;
    node.children.forEach(function (item) {
      keys = keys.concat(self.getNodeKeys(item));
    });
    return keys;
  }

  async getChilds(
    catalogId: string,
    categoryId: string,
    customerId: string,
    filter: string[],
    selectedStates: string[],
    productSearchOptions: ProductSearchOptions,
    searchStatesMode: string = null
  ): Promise<TreeElement[]> {
    let params = new HttpParams()
      .append('customerId', customerId)
      .append('catalogId', catalogId)
      .append('categoryId', categoryId);

    if (filter) params = params.append('filter', filter.join(';'));

    if (selectedStates) params = params.append('states', selectedStates.join(';'));
    if (productSearchOptions && productSearchOptions.searchFields) {
      let fields = new Array<string>();
      productSearchOptions.searchFields.forEach((value) => {
        fields.push(value.searchField);
      });

      params = params.append('fields', fields.join(';'));

      params = params.append('searchMode', productSearchOptions.searchMode);
    }

    params = params.append('searchStatesMode', searchStatesMode);

    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: params
    };

    return await this.http
      .get<TreeElement[]>('api/catalog/GetTreeChilds', options)
      .pipe(map((result: TreeElement[]) => (this.childs = result)))
      .toPromise();
  }

  getSpidsInCatalog(catalogId: string, customerId: string): Observable<Array<string>> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('catalogId', catalogId).append('customerId', customerId)
    };

    return this.http.get<Array<string>>('api/product/GetSpids', options);
  }

  /*******************************************************************************/
  /*                            KATALOG LIST                                     */
  /*******************************************************************************/

  public catalogs: Catalog[];
  tempCatalogs: Catalog[];

  setCatalogs(customerId: string): void {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };

    this.http
      .get('api/catalog/getCatalogs', options)
      .pipe(map((result: Catalog[]) => (this.tempCatalogs = result)))
      .subscribe(
        (success) => {
          if (success) {
            this.setResponsible();
            this.setCatalogGroupe();
            this.catalogs = this.tempCatalogs;
          }
        },
        (error) => {
          return false;
        }
      );
  }

  public setResponsible() {
    this.responsibleList = [];
    for (let catalog of this.tempCatalogs) {
      if (catalog.responsible != null) {
        if (this.responsibleList.find((element) => element == catalog.responsible) == undefined) {
          this.responsibleList.push(catalog.responsible);
        }
      }
    }
  }

  public setCatalogGroupe() {
    this.catalogGroupList = [];
    for (let catalog of this.tempCatalogs) {
      if (catalog.pimGroup != null) {
        if (this.catalogGroupList.find((element) => element == catalog.pimGroup) == undefined) {
          this.catalogGroupList.push(catalog.pimGroup);
        }
      }
    }
  }

  public responsibleList: string[] = [];
  public catalogGroupList: string[] = [];

  getCatalogs(customerId: string): Observable<Catalog[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };
    return this.http.get<Catalog[]>('api/catalog/getCatalogs', options) as any;
  }

  public copyCatalogId: string = '';
  public copyCatalogCustomerId: string = '';
  private isBoolean = (val) => 'boolean' === typeof val;

  /*******************************************************************************/
  /*                            KATALOG                                          */
  /*******************************************************************************/

  _selectedCatalogId = '';
  get selectedCatalogId() {
    return this._selectedCatalogId;
  }

  set selectedCatalogId(value) {
    this._selectedCatalogId = value;
    if (value) this.updateRefreshBehavior(value);
    else this.jobService.closeCatalogFeed(this.loginService.currentUser.customerId, value).catch(console.error);
  }

  _selectedTargetCatalogId = '';
  get selectedTargetCatalogId() {
    return this._selectedTargetCatalogId;
  }

  set selectedTargetCatalogId(value) {
    this._selectedTargetCatalogId = value;
  }

  private _catalog: Catalog;
  public isCatalogSelected: boolean = false;

  get catalog(): Catalog {
    return this._catalog;
  }

  set catalog(value: Catalog) {
    this._catalog = value;
  }

  addCatalog(newCatalog: NewCatalog) {
    return this.http.post('api/catalog/addCatalog', newCatalog).pipe(
      map((response: NewCatalog) => {
        const result = response;
        this.selectedCatalogId = result.catalogId;
        return true;
      })
    );
  }

  remCatalog(catalogId: string) {
    if (this.copyCatalogId == catalogId) {
      this.copyCatalogId = '';
    }
    this.http.delete('api/catalog/DeleteCatalog/' + catalogId).subscribe();
  }

  getCatalog(catalogId: string, customerId: string): Observable<Catalog> {
    this._catalog = null;
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };
    return this.http
      .get<Catalog>('api/catalog/getCatalog', options)
      .pipe(map((result: Catalog) => (this._catalog = result))) as any; // EVIL!!!!
  }

  getCatalogNoSet(catalogId: string, customerId: string): Observable<Catalog> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };
    return this.http.get<Catalog>('api/catalog/getCatalog', options);
  }

  getCatalogsContainingSupplierPID(
    catalogId: string,
    customerId: string,
    targetCustomerId: string
  ): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('catalogId', catalogId)
        .append('targetCustomerId', targetCustomerId)
    };
    return this.http.get<string[]>('api/catalog/GetCatalogsContainingSupplierPID', options);
  }

  getCatalogsContainingEAN(catalogId: string, customerId: string, targetCustomerId: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('catalogId', catalogId)
        .append('targetCustomerId', targetCustomerId)
    };
    return this.http.get<string[]>('api/catalog/GetCatalogsContainingEAN', options);
  }

  getCatalogsContainingSupplierAltPID(
    catalogId: string,
    customerId: string,
    targetCustomerId: string
  ): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('catalogId', catalogId)
        .append('targetCustomerId', targetCustomerId)
    };
    return this.http.get<string[]>('api/catalog/GetCatalogsContainingSupplierAltPID', options);
  }

  catalogChanged(e) {
    if (this._catalog != null) {
      if (e.previousValue !== e.value) {
        const result = ValidationEngine.validateGroup('catalogValidationGroup');
        if (result.isValid) {
          this.updateCatalog();
        }
      }
    }
  }

  updateCatalog(event = null): Promise<boolean> {
    this.sortMimes(this._catalog.mimes);
    if (event == null || event.event != null) {
      return this.http
        .post<StringResponse>('api/catalog/UpdateCatalog', this._catalog)
        .toPromise()
        .then(
          (success) => {
            if (success && this.treeList != null) {
              var node = this.treeList.instance.getRootNode();
              if (node != null) {
                node = node.children[0];
                node.data['text'] = this._catalog.catalogName;
              }
              return true;
            }
          },
          (error) => {
            return false;
          }
        );
    }
  }

  createRestorePoint(id: string) {
    let duplicateCatalog = new DuplicateItem();
    duplicateCatalog.sourceCustomerId = this.loginService.currentUser.customerId;
    duplicateCatalog.sourceItemId = id;

    this.http.post('api/backup/createRestorePoint', duplicateCatalog).subscribe(
      (success) => {
        if (success) {
          return true;
        }
      },
      (error) => {
        return false;
      }
    );
  }

  duplicateCatalog(id: string, sourceCustomer: string = null) {
    if (sourceCustomer == null) {
      sourceCustomer = this.loginService.currentUser.customerId;
    }

    var duplicateCatalog = new DuplicateItem();
    duplicateCatalog.sourceCustomerId = sourceCustomer;
    duplicateCatalog.targetCustomerId = this.loginService.currentUser.customerId;
    duplicateCatalog.sourceItemId = id;

    this.http.post('api/catalog/duplicateCatalog', duplicateCatalog).subscribe(
      (success) => {
        this.requestCatalogChangingJobs();
        if (success) {
          return true;
        }
      },
      (error) => {
        return false;
      }
    );
  }

  spreadCatalog(duplicateCatalog: DuplicateItem) {
    this.http.post('api/catalog/duplicateCatalog', duplicateCatalog).subscribe(
      (success) => {
        this.requestCatalogChangingJobs();
        if (success) {
          return true;
        }
      },
      (error) => {
        return false;
      }
    );
  }

  checkVirtualCatalogs(catalogId: string, customerId: string): Promise<Record<string, number>> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("catalogId", catalogId)
    }
    
    var result = this.http.get<Record<string, number>>("api/catalog/getAllLinkedVirtualCatalogs", options);
    return lastValueFrom(result);
  }

  /*******************************************************************************/
  /*                            SHOP Verbindung                                  */
  /*******************************************************************************/

  public connection2Shop = false;
  public connection2ShopMessage = '';
  public connection2ShopEstablished = false;

  //SHOP

  public resetConnection() {
    this.connection2Shop = false;
    this.connection2ShopMessage = '';
    this.connection2ShopEstablished = false;
  }

  public testShopConnection() {
    this.resetConnection();
    this.shopService
      .testShopConnection(this.catalog.shopUrl, this.catalog.shopAuth)
      .subscribe((result: TestResult) => this.testShopResult(result));
  }

  public testShopWare6Connection() {
    this.resetConnection();

    this.shopService
      .testShopWare6Connection(this.catalog.shopWareConnection)
      .subscribe((result: TestResult) => this.testShopResult(result));
  }

  private testShopResult(result: TestResult) {
    if (result.success) {
      this.connection2Shop = true;
      this.getConnection2Shop();
    } else {
      this.connection2ShopMessage = result.message;
    }
  }

  public getConnection2Shop() {
    if (this.catalog == null) {
      return;
    }
    this.shopService
      .getConnection2Shop(this.catalog.shopUrl, this.catalog.shopAuth)
      .subscribe((result: ShopConnection) => this.getConnection2ShopResult(result));
  }

  private getConnection2ShopResult(result: ShopConnection) {
    if (result.customerId == this.loginService.currentUser.customerId && result.catalogId == this.catalog.id) {
      this.connection2ShopEstablished = true;
    }
  }

  GetShopwareSalesChannel(baseUri: string, clientId: string, clientSecret: string): Observable<ShopwareSalesChannel[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('baseUri', baseUri)
        .append('clientId', clientId)
        .append('clientSecret', clientSecret)
    };

    return this.http.get<ShopwareSalesChannel[]>('api/ShopConnector/GetSalesChannel', options);
  }

  GetShopwareLanguages(baseUri: string, clientId: string, clientSecret: string): Observable<ShopwareLanguage[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('baseUri', baseUri)
        .append('clientId', clientId)
        .append('clientSecret', clientSecret)
    };

    return this.http.get<ShopwareLanguage[]>('api/ShopConnector/GetLanguages', options);
  }

  GetShopwareCategories(baseUri: string, clientId: string, clientSecret: string): Observable<TreeElement[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('baseUri', baseUri)
        .append('clientId', clientId)
        .append('clientSecret', clientSecret)
    };

    return this.http.get<TreeElement[]>('api/ShopConnector/GetCategories', options);
  }

  GetShopwareCmsPage(baseUri: string, clientId: string, clientSecret: string): Observable<ShopwareCmsPage[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('baseUri', baseUri)
        .append('clientId', clientId)
        .append('clientSecret', clientSecret)
    };

    return this.http.get<ShopwareCmsPage[]>('api/ShopConnector/GetShopwareCmsPage', options);
  }

  public establishedShopConnection() {
    this.shopService
      .establishedShopConnection(
        this.catalog.shopUrl,
        this.catalog.shopAuth,
        this.loginService.currentUser.customerId,
        this.catalog.id
      )
      .subscribe((result: TestResult) => this.establishedShopResult(result));
  }

  private establishedShopResult(result: TestResult) {
    this.connection2ShopEstablished = false;
    if (result.success) {
      this.connection2ShopEstablished = true;
    }
  }

  private _catalogNameCache: Map<string, string> = new Map<string, string>();

  public async getCatalogName(catalogId: string, customerId: string): Promise<string> {
    if (catalogId == '' || catalogId == '000000000000000000000000') return '';

    if (this.catalogs && this._catalogNameCache.size < this.catalogs.length) {
      this.catalogs.forEach((x) => {
        this._catalogNameCache.set(x.id, x.catalogName);
      });
    }

    if (!this._catalogNameCache.has(catalogId)) {
      const options = {
        headers: new HttpHeaders().append('Content-Type', 'application/json'),
        params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
      };

      let catalogName = await this.http.get<{ name: string }>('api/catalog/getName', options).toPromise();
      this._catalogNameCache.set(catalogId, catalogName.name);
    }

    return this._catalogNameCache.get(catalogId);
  }

  /*******************************************************************************/
  /*                            KATEGORY                                         */
  /*******************************************************************************/
  private duplicateCategory = new DuplicateItem();

  private _category: Category;
  public isCategorySelected: boolean = false;

  get category(): Category {
    return this._category;
  }

  set category(value: Category) {
    this._category = value;
  }

  async addCategory(parentCatId: string, customerId: string, treeId: string) {
    this.addItem(parentCatId, true, customerId, treeId);
  }

  remCategory(obj: any, treeElementId: string) {
    this.remItem(obj, true, treeElementId);
  }

  getCategory(categoryId: string, customerId: string): Observable<Category> {
    this.category = null;
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('categoryId', categoryId)
    };

    return this.http
      .get<Category>('api/category/getCategory', options)
      .pipe(map((result: Category) => (this._category = result))) as any;
  }

  getCategoryFullname(categoryId: string, customerId: string): Observable<Category> {
    this.category = null;
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('categoryId', categoryId)
    };

    return this.http
      .get<Category>('api/category/getCategoryFullname', options)
      .pipe(map((result: Category) => (this._category = result))) as any;
  }

  categoryChanged(e) {
    if (this._category != null) {
      if (e != null && e.previousValue !== e.value) {
        const result = ValidationEngine.validateGroup('categoryValidationGroup');
        if (result.isValid) {
          if (this._category.groupOrder == null) this._category.groupOrder = 0;

          this.updateCategory();
        }
      }
    }
  }

  updateCategory() {
    this.sortMimes(this._category.mimes);
    this.http.post<StringResponse>('api/category/UpdateCategory', this._category).subscribe(
      (success) => {
        if (success) {
          const node = this.treeList.instance.getNodeByKey(this._category.id);
          node.data['text'] = this._category.groupName;
          if (node.data['order'] != this._category.groupOrder) {
            this.treeRefresh();
          }
          return true;
        }
      },
      (error) => {
        return false;
      }
    );
  }

  /*******************************************************************************/
  /*                            PRODUCT                                          */
  /*******************************************************************************/
  public duplicateProduct = new DuplicateItem();

  private _product: Product;
  public isProductSelected: boolean = false;

  public addEcommerceFeatures(product: Product): Observable<Product> {
    return this.http.post('api/product/addEcommerceFeatures', product) as any;
  }

  public linkAllFields(product: Product): Observable<Product> {
    return this.http.post('api/product/linkAllFields', product) as any;
  }

  addEcommerceFeaturesToCatalog(catalog: Catalog) {
    return this.http.post('api/catalog/addEcommerceFeatures', catalog) as any;
  }
  addEcommerceFeaturesToCategory(category: Category) {
    return this.http.post('api/category/addEcommerceFeatures', category) as any;
  }

  public getProductWithGeneratedText(
    product: Product,
    catalogName: string,
    teachingSetId: string,
    generateHTML: boolean,
    maxLength: number,
    textStyle: string
  ): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('catalogName', catalogName)
        .append('teachingSetId', teachingSetId)
        .append('generateHTML', generateHTML)
        .append('maxLength', maxLength)
        .append('textStyle', textStyle)
    };

    return this.http.post('api/product/getProductWithGeneratedText', product, options) as any;
  }

  get product(): Product {
    return this._product;
  }

  set product(value: Product) {
    this._product = value;
    if (this._product) {
      this._product.prepareView();
    }
  }

  async addProduct(parentId: string, customerId: string, treeId: string) {
    this.addItem(parentId, false, customerId, treeId);
  }

  remProduct(obj: any, treeElementId: string) {
    this.remItem(obj, false, treeElementId);
  }

  getProduct(productId: string, customerId: string, catalogId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('productId', productId)
        .append('catalogId', catalogId)
    };

    return this.http
      .get<Product>('api/product/GetProduct', options)
      .pipe(map((result: Product) => this.setProduct(result, true))) as any;
  }

  getProductUnchecked(productId: string, customerId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('productId', productId)
    };

    return this.http
      .get<Product>('api/product/GetProductUnchecked', options)
      .pipe(map((result: Product) => this.setProduct(result, true))) as any;
  }

  getRawProduct(productId: string, customerId: string): Observable<object> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('productId', productId)
    };
    return this.http.get<object>('api/product/GetRawProduct', options);
  }

  getFirstProduct(customerId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };

    return this.http
      .get<Product>('api/product/GetFirstProduct', options)
      .pipe(map((result: Product) => this.setProduct(result, true))) as any;
  }

  getProductContent(supplierPid: string, customerId: string, catalogId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('supplierPid', supplierPid)
        .append('catalogId', catalogId)
    };

    return this.http.get<Product>('api/product/GetProductContent', options);
  }

  getProductBySupplierPid(supplierPid: string, customerId: string, catalogId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('supplierPid', supplierPid)
        .append('catalogId', catalogId)
    };

    return this.http.get<Product>('api/product/GetProductBySupplierPid', options).pipe(
      map((result: Product) => {
        return result;
      })
    ) as any;
  }

  searchProducts(filter: string, customerId: string, page: number, pageSize: number): Observable<SearchResult[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('filter', filter)
        .append('page', page.toString())
        .append('pageSize', pageSize.toString())
    };

    return this.http.get<SearchResult[]>('api/product/SearchProducts', options).pipe(
      map((result: SearchResult[]) => {
        return result;
      })
    ) as any;
  }

  public setProduct(product: Product, holdHints: boolean = false): Product {
    product = this.productService.getProductFromJson(product, this.loginService.wawiSettings);
    if (product) {
      product.prepareView();
    }
    if (holdHints && this._product) {
      product.hintsActiv = this._product.hintsActiv;
    }
    this._product = product;
    return product;
  }

  getLanguageCodes(): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };

    return this.http
      .get<string[]>('api/Translation/GetLanguageCodes', options)
      .pipe(map((result: string[]) => (this.languageCodes = result))) as any;
  }

  getCustomerProductStates(customerId: string): Observable<ProductState[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };

    return this.http
      .get<ProductState[]>('api/ProductState/GetProductStates', options)
      .pipe(map((result: ProductState[]) => (this.productStates = result))) as any;
  }

  validateProductForm(type: string) {
    if (ValidationEngine.getGroupConfig(type)) {
      ValidationEngine.validateGroup(type);
      this._product.activateHints();
    }
  }

  private tmpid = '';
  updateProduct(event = null): Promise<object> {
    let that = this;
    return new Promise(function (resolve, reject) {
      if (event != null) {
        if (isString(event.value) || isString(event.previousValue)) {
          if (event.value === event.previousValue) {
            return;
          }
        } else if (Array.isArray(event.value) || Array.isArray(event.previousValue)) {
          if (JSON.stringify(event.value) == JSON.stringify(event.previousValue)) {
            return;
          }
        } else if (isNumber(event.value) || isNumber(event.previousValue)) {
          if (event.value === event.previousValue) {
            return;
          }
        } else if (typeof event.value == 'boolean' || typeof event.previousValue == 'boolean') {
          if (event.value === event.previousValue) {
            return;
          }
        } else if (event.data != null && event.data.artIdTo != null) {
        } else {
          console.warn('Keine Updateüberprüfung');
          console.warn(event);
        }
      }
      that.tmpid = that._product.id;
      that.sortMimes(that._product.mimes);
      that.sortFeatureSystems(that._product.featureSystems);
      if (that._product.productOrder == null) that._product.productOrder = 0;
      // Fix a bug in copy Category
      if (that._product.customerId != that.loginService.currentCustomer.id) {
        that._product.customerId = that.loginService.currentCustomer.id;
      }

      let mimeChange: boolean = false;
      if (event != null && event.value != null) {
        for (let mime of that._product.mimes) {
          if (
            event.value == mime.mimeOrder ||
            event.value == mime.mimePurpose ||
            event.value == mime.mimeSource ||
            event.value == mime.mimeType ||
            event.value == mime.mimeDescr ||
            event.value == mime.mimeAlt
          ) {
            mimeChange = true;
          }
        }
      }

      //this.http.post<StringResponse>("api/product/UpdateProduct", this._product)
      that.http.post<Product>('api/product/UpdateProduct', that._product).subscribe(
        (success) => {
          if (success) {
            if (that._product != null && that.tmpid == that._product.id) {
              let order = 1;

              // Wird beim toggle eines LinkedField an einem CHILD gesetzt
              if (event != null && event.forceReload != null && event.forceReload == true) {
                that.product = that.productService.getProductFromJson(success, that.loginService.wawiSettings); // "neu laden"
              }

              that._product.catalogMappings.forEach(function (mapping) {
                const node = that.treeList.instance.getNodeByKey(that._product.id + '_' + mapping.categoryId);
                if (node) {
                  node.data['text'] = that._product.descriptionShort;
                  node.data['supplierPid'] = that._product.supplierPid;
                  order = node.data['order'];
                }
              });

              that.checkPriceLists(); //Prüfen, ob Pricelist zum Katalog hinzugefügt werden muss

              if (that._product.productOrder != null && order != that._product.productOrder) {
                that.treeRefresh();
              } else if (
                (that._product.productCount != null && that._product.productCount > 0) ||
                (that._product.isChild != null && that._product.isChild)
              ) {
                // Bei relevanten Änderungen am MASTER / CHILD muss der TREE neu geladen werden, damit die Anzeige passt:
                let needsRefresh: boolean = false;

                // forceTreeRefresh deckt dies NICHT mit ab!! forceTreeRefresh wird nur beim toggle eines LinkedField an einem CHILD gesetzt!!
                if (
                  (event != null && event.value == that._product.descriptionShort) ||
                  (event != null && event.value == that._product.supplierPid)
                ) {
                  needsRefresh = true;
                }

                // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
                if (event != null && event.forceTreeRefresh != null && event.forceTreeRefresh == true) {
                  needsRefresh = true;
                }

                if (mimeChange || needsRefresh) {
                  //if (needsRefresh) {
                  that.treeRefresh();
                }
              }
            }
            resolve(success);
          } else {
            reject(success);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  checkPriceLists() {
    var needCatalogUpdate = false;
    var priceList;
    for (let produtPriceList of this._product.priceLists) {
      priceList = null;
      priceList = this._catalog.priceLists.filter((element) => element.order == produtPriceList.priceListOrder).shift();
      if (priceList == null && produtPriceList.priceListOrder > 0) {
        var newCatalogPriceList = new CatalogPriceList(produtPriceList.priceListOrder, '');
        this._catalog.priceLists.push(newCatalogPriceList);
        needCatalogUpdate = true;
      }
      if (needCatalogUpdate) this.updateCatalog();
    }
  }

  GetProductTreeItem(
    productId: string,
    customerId: string,
    categoryId?: string,
    catalogId?: string
  ): Observable<TreeItem> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('productId', productId)
        .append('customerId', customerId)
        .append('categoryId', categoryId)
        .append('catalogId', catalogId)
    };

    return this.http.get<TreeItem>('api/Product/GetProductTreeItem', options);
  }

  /*******************************************************************************/
  /*                            SHARED                                           */
  /*******************************************************************************/

  private sortMimes(mimes: Mime[]) {
    if (mimes.length == 1) {
      if (mimes[0].mimeOrder == null) {
        mimes[0].mimeOrder = 0;
      }
    }

    mimes.sort(function (a: Mime, b: Mime): number {
      if (a.mimeOrder == null || a.mimeOrder == 0) {
        a.mimeOrder = 0;
      }
      if (b.mimeOrder == null || b.mimeOrder == 0) {
        b.mimeOrder = 0;
      }
      if (a.mimeOrder < b.mimeOrder) {
        return -1;
      }
      if (a.mimeOrder > b.mimeOrder) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    });
  }

  private sortFeatureSystems(featureSystem: FeatureSystem[]) {
    if (featureSystem.length == 1) {
      if (featureSystem[0].order == null) {
        featureSystem[0].order = 0;
      }
    }

    featureSystem.sort(function (a: FeatureSystem, b: FeatureSystem): number {
      if (a.order == null || a.order == 0) {
        a.order = 0;
      }
      if (b.order == null || b.order == 0) {
        b.order = 0;
      }
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      // a muss gleich b sein
      return 0;
    });
    featureSystem.forEach(function (fs: FeatureSystem) {
      if (fs && fs.features) {
        if (fs.features.length == 1) {
          if (fs.features[0].forder == null) {
            fs.features[0].forder = 0;
          }
        }

        fs.features.sort(function (a: Feature, b: Feature): number {
          if (a.forder == null || a.forder == 0) {
            a.forder = 0;
          }
          if (b.forder == null || b.forder == 0) {
            b.forder = 0;
          }
          if (a.forder < b.forder) {
            return -1;
          }
          if (a.forder > b.forder) {
            return 1;
          }
          // a muss gleich b sein
          return 0;
        });
      }
    });
  }

  refreshCountInTree(parentCatId: string, isCategory: boolean) {
    const node = this.treeList.instance.getNodeByKey(parentCatId);
    if (node.data != undefined) {
      if (isCategory) {
        let categoryCount = node.data['categoryCount'];
        node.data['categoryCount'] = categoryCount + 1;
      } else {
        let productCount = node.data['productCount'];
        node.data['productCount'] = productCount + 1;
      }
      node.data['hasChilds'] = true;
      if (node.data['parent']) {
        this.refreshCountInTree(node.data['parent'], isCategory);
      }
    }
  }

  addItem(parentId: string, isCategory: boolean, customerId: string, treeId: string) {
    let addItemModel = new AddItem();
    addItemModel.parentId = parentId;
    addItemModel.customerId = customerId;
    addItemModel.parentTreeId = treeId;
    var response;
    if (isCategory) {
      this.http.post<StringResponse>('api/category/AddCategory', addItemModel).subscribe((val: StringResponse) => {
        response = val;
        this.refreshTree(treeId, response.value);
      });
    } else {
      this.http.post<StringResponse>('api/product/AddProduct', addItemModel).subscribe((val: StringResponse) => {
        response = val.value;
        this.refreshTree(treeId, response + '_' + this.getCategoryFromTreeId(treeId));
      });
    }
  }

  getCategoryFromTreeId(parentId: string): string {
    var splitted = parentId.split('_', 2);

    if (splitted.length < 2) return parentId;
    return splitted[1];
  }

  // UNUSED
  //public setduplicateSource(sourceId: string, isCategory: boolean, sourceCustomerId: string) {
  //  let duplicateItemModel = this.duplicateProduct;
  //  if (isCategory) {
  //    duplicateItemModel = this.duplicateCategory;
  //  }
  //  duplicateItemModel.sourceCustomerId = sourceCustomerId;
  //  duplicateItemModel.sourceItemId = sourceId;
  //}

  duplicateItem(targetParentId: string, isCategory: boolean, targetCustomerId: string, sourceId: string = '') {
    let duplicateItemModel = new DuplicateItem();
    if (sourceId == '') {
      duplicateItemModel = this.duplicateProduct;
      if (isCategory) {
        duplicateItemModel = this.duplicateCategory;
      }
    } else {
      duplicateItemModel.sourceCustomerId = targetCustomerId;
      duplicateItemModel.sourceItemId = sourceId;
    }

    duplicateItemModel.targetCustomerId = targetCustomerId;
    duplicateItemModel.targetItemId = targetParentId;

    duplicateItemModel.targetCatalogId = this.selectedCatalogId;
    duplicateItemModel.targetCategoryId = targetParentId;

    var response;
    if (isCategory) {
      // es gibt keinen auruf für diesen zweig...
      this.http
        .post<StringResponse>('api/Category/CopyCategory', duplicateItemModel)
        .subscribe((val: StringResponse) => {
          response = val.value;
          this.refreshTree(targetParentId, response.value);
        });
    } else {
      this.http.post<StringResponse>('api/Product/CopyProduct', duplicateItemModel).subscribe((val: StringResponse) => {
        response = val.value;
        this.refreshTree(targetParentId, response.value);
      });
    }
  }

  _selectedTreeElement: string;
  set selectedTreeElement(v: string) {
    this._selectedTreeElement = v;
    this.selectTreeElement(v);
  }
  get selectedTreeElement(): string {
    return this._selectedTreeElement;
  }

  selectTreeElement(id: string) {
    if (id != '' && id) {
      var keys = new Array<string>();
      keys.push(id);
      var result = this.treeList.instance.selectRows(keys, false);
    }
    return null;
  }

  refreshTree(parentId: string, newId: string) {
    let that = this;
    // this.refreshCountInTree(parentId, isCategory);
    // ReSharper disable once TsResolvedFromInaccessibleModule
    //Neu Laden der Kategorien
    this.treeList.instance.refresh().then(function () {
      //Ausklappen des Elternelementes
      that.treeList.instance.expandRow(parentId).then(function () {
        that.selectedTreeElement = newId;
      });
    });
  }

  reloadTree() {
    this.treeList?.instance?.refresh().then(function () {});
  }
  reloadTreeTarget() {
    this.treeListTarget?.instance?.refresh().then(function () {});
  }

  remItem(obj: any, isCategory: boolean, treeElementId: string) {
    let observable;
    this.isCategorySelected = false;
    this.isCatalogSelected = false;
    this.isProductSelected = false;
    //  let selectedRows = this.treeList.instance.getSelectedRowKeys();
    let selectedRowObj = this.treeList.instance.getNodeByKey(treeElementId);
    let parentNodeKey = selectedRowObj.parent.key;

    let id;
    if (isCategory) {
      id = this._category.id;
      if (this.duplicateCategory.sourceItemId == id) {
        this.duplicateCategory.sourceItemId = null;
      }
    } else {
      id = this._product.id;
      if (this.duplicateProduct.sourceItemId == id) {
        this.duplicateProduct.sourceItemId = null;
      }
    }

    let keys = new Array<string>();
    keys.push(parentNodeKey);
    this.treeList.instance.selectRows(keys, false);
    if (isCategory) {
      observable = this.http.delete(
        'api/category/DeleteCategory/' + this.loginService.currentUser.customerId + '/' + id
      );
    } else {
      observable = this.http.delete(
        'api/product/DeleteProduct/' + this.loginService.currentUser.customerId + '/' + treeElementId
      );
    }

    observable.subscribe(() => {
      this.treeRefresh();
    });
  }

  getThumbUrl(id: string, type: string, customerId: string, catalogId: string): Observable<UrlResponse> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('catalogId', catalogId)
        .append('type', type)
        .append('id', id)
    };

    return this.http.get<UrlResponse>('api/catalog/GetThumbUrl', options) as any;
  }

  /*******************************************************************************/
  /*                            JOBS                                             */
  /*******************************************************************************/

  createExportJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/CreateExportJob', exportParameter);
  }

  createNewCatalogExportJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/CreateNewCatalogExportJob', exportParameter);
  }

  createGevisExportJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/CreateGevisExportJob', exportParameter);
  }

  createExportToCustomerWithValidationJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/createExportToCustomerWithValidationJob', exportParameter);
  }

  createSmartStoreExportJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/CreateSmartStoreExportJob', exportParameter);
  }

  createEbayExportJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/CreateEbayExportJob', exportParameter);
  }

  createIntershopExportJob(exportParameter: ExportParameter) {
    return this.http.post('api/job/CreateIntershopExportJob', exportParameter);
  }

  downloadMimes(catalogId: string, customerId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('catalogId', catalogId).append('customerId', customerId)
    };
    return this.http.get('api/catalog/DownloadMimes', options) as any;
  }

  /*******************************************************************************/
  /*                            EXPORTS                                          */
  /*******************************************************************************/

  public exportVisible: boolean = false;

  public translateVisible: boolean = false;

  getFeatureSystems(catalogId: string, customerId: string): Observable<FeatureSystem[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<FeatureSystem[]>('api/catalog/getFeatureSystems', options) as any;
  }

  getFeatureSystemFilters(catalogId: string, customerId: string): Observable<FeatureSystemFilter[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<FeatureSystemFilter[]>('api/catalog/getFeatureSystemFilters', options) as any;
  }

  getFeatureSystemTemplateNames(catalogId: string, customerId: string): Observable<KeyValueItem[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<FeatureSystemFilter[]>('api/catalog/getFeatureSystemTemplateNames', options) as any;
  }

  getFeatureNamesBySystem(catalogId: string, customerId: string, featuresSystemName: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('catalogId', catalogId)
        .append('featuresSystemName', featuresSystemName)
    };

    return this.http.get<string[]>('api/catalog/getFeatureNamesBySystem', options) as any;
  }

  getAllMimePurposesInCatalog(catalogId: string, customerId: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<string[]>('api/catalog/GetAllMimePurposesInCatalog', options) as any;
  }

  getUnitsThatCanBeSplitFromFeatureValues(catalogId: string, customerId: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<string[]>('api/catalog/getUnitsThatCanBeSplitFromFeatureValues', options) as any;
  }

  getFeatureNames(catalogId: string, customerId: string): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<string[]>('api/catalog/getFeatureNames', options) as any;
  }

  getUdxFilters(catalogId: string, customerId: string): Observable<FeatureSystemFilter[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<FeatureSystemFilter[]>('api/catalog/getUdxFilters', options) as any;
  }

  getPriceListFilters(catalogId: string, customerId: string): Observable<PriceListFilter[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<PriceListFilter[]>('api/catalog/getPriceListFilters', options) as any;
  }

  getCategoryFilters(catalogId: string, customerId: string): Observable<TreeElement[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<TreeElement[]>('api/catalog/getCategoryFilters', options) as any;
  }

  getUdxSelectFilters(catalogId: string, customerId: string): Observable<TreeElement[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('catalogId', catalogId)
    };

    return this.http.get<TreeElement[]>('api/catalog/getUdxSelectFilters', options) as any;
  }

  /*******************************************************************************/
  /*                            TEMPLATES                                        */
  /*******************************************************************************/

  public fields: TemplateItemTemplate[] = [];

  public loadFields() {
    this.loadFields2Subscribe().subscribe();
  }

  public loadFields2Subscribe(): Observable<any> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', this.loginService.currentUser.customerId)
    };
    return this.http.get<TemplateItemTemplate[]>('api/catalog/getExcelTemplateItemTemplate', options).pipe(
      map((result: TemplateItemTemplate[]) => {
        if (this.fields && this.fields.length > 0)
          result.forEach((x) => (x.active = this.fields.some((y) => y.active && y.field == x.field)));
        this.fields = result;
      })
    );
  }

  /*******************************************************************************/
  /*                            CLIPBOARD                                        */
  /*******************************************************************************/

  public clipboard: Clipboard = null;

  loadClipboard(): Observable<Clipboard> {
    const d = new Date();
    let ticks = d.getTime();

    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('userId', this.loginService.currentUser.id).append('dateTime', ticks.toString())
    };

    return this.http
      .get<Clipboard>('api/Clipboard/getClipboard', options)
      .pipe(map((result: Clipboard) => (this.clipboard = result))) as any;
  }

  saveClipboard<Clipboard>(clipboard: Clipboard) {
    this.clipboard = clipboard;
    this.http.post('api/Clipboard/SetClipboard', clipboard).subscribe();
  }

  saveClipboard2Subscribe<Clipboard>(clipboard: Clipboard): Observable<object> {
    this.clipboard = clipboard;
    return this.http.post('api/Clipboard/SetClipboard', clipboard);
  }

  pasteItem(objectId: string) {
    var isCategory = true;
    if (this.clipboard.content == 'pro') {
      isCategory = false;
    }
    var duplicateItemModel = new DuplicateItem();
    duplicateItemModel.sourceCustomerId = this.clipboard.customerId;
    duplicateItemModel.targetCustomerId = this.loginService.currentUser.customerId;
    duplicateItemModel.sourceItemId = this.clipboard.objectId;
    duplicateItemModel.targetItemId = objectId;

    var response;
    if (isCategory) {
      let loadingText = '';
      this.translate.get('Loading').subscribe((text: string) => {
        loadingText = text;
      });
      this.treeList.instance.beginCustomLoading(loadingText);

      this.http
        .post<StringResponse>('api/Category/CopyCategory', duplicateItemModel)
        .subscribe((val: StringResponse) => {
          response = val.value;

          this.treeList.instance.endCustomLoading();

          this.refreshTree(objectId, response);
        });
    } else {
      duplicateItemModel.targetCategoryId = objectId;
      duplicateItemModel.targetCatalogId = this.catalog.id;

      this.http.post<StringResponse>('api/Product/CopyProduct', duplicateItemModel).subscribe(
        (val: StringResponse) => {
          response = val.value;
          this.refreshTree(objectId, response);
        },
        (error) => {
          this.systemService.notifyWarning(
            'Produkt konnte nicht kopiert werden, bitte stellen Sie sicher, dass das Produkt noch existiert'
          );
        }
      );
    }
  }

  /*******************************************************************************/
  /*                            AUTORELOAD                                       */
  /*******************************************************************************/

  public isJobRunning: boolean = false;
  public jobStatus: 'idle' | 'started' | 'running' | 'ended' | 'error' = 'idle';
  public counter: number = 0;

  public async requestCatalogChangingJobs() {
    const user = this.loginService.currentUser;
    if (user?.customerId) {
      await this.jobService.feedCatalogChangingJobs(user.customerId);
    }
  }

  private updateRefreshBehavior(catalogId: string) {
    if (!this.loginService?.currentUser?.customerId) return;
    this.jobService.feedRefreshJobs(this.loginService?.currentUser?.customerId, catalogId).catch(console.error);
  }

  private handleRefreshBehavior() {
    this.loginService.userUpdate$.subscribe(async (user) => {
      const promises: Promise<unknown>[] = [];
      if (user?.customerId) {
        promises.push(this.jobService.feedCatalogChangingJobs(user.customerId));
      }

      await Promise.all(promises).catch(console.error);
    });

    this.jobService.refreshJobs$.subscribe(({ running, changed }) => {
      this.isJobRunning = running;

      if (running || !changed) return;

      const text =
        this.jobService.refreshJobFeedbackText ?? this.translate.instant('Katalog wurde durch Job verändert');
      this.jobService.refreshJobFeedbackText = null;
      this.systemService.notifyInfo(text, 3000);

      this.treeRefresh();
      if (this.isCatalogSelected)
        this.getCatalog(this.catalog.id, this.loginService.currentUser.customerId).subscribe();
      if (this.isCategorySelected)
        this.getCategory(this.category.id, this.loginService.currentUser.customerId).subscribe();
      if (this.isProductSelected)
        this.getProduct(this.product.id, this.loginService.currentUser.customerId, this.catalog.id).subscribe();
    });

    this.jobService.catalogChangingJobs$.subscribe(({ status }) => {
      if (this.jobStatus === 'idle' && status === 'idle') return;
      this.jobStatus = status;

      // Aktualisierung beenden
      if (status === 'ended') {
        this.setCatalogs(this.loginService.currentUser.customerId);
      }
    });

    if (this.selectedCatalogId != null && this.selectedCatalogId != '') {
      const params = new URLSearchParams();
      params.set('catalogId', this.selectedCatalogId);
      params.set('customerId', this.selectedCatalogId);
    }
  }
}
